import IconsContent from "../../Assets/img/IconsContent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { DatePickerInputRange } from "../DatePicker/DatePicker";
import TableFirst from "../TableFirst/TableFirst";
import { useForm } from 'react-hook-form'
import { UserService } from "../../../Main/Services/UserService";
import { InputSelect } from "../Select/InputSelect";
import { useTranslation } from "react-i18next";
import ExportExcel from "./ExportExcel";


export default function ProjectsContainer({ projects, setFilteredPage, filteredPage, documentTypes, expertsList, authorityDevelopers, setFilteredData, npaData }) {
    const userLocation = localStorage.getItem('.UserLocation')
    const { t, i18n } = useTranslation("common");
    const [projectsFiltered, setProjectsFiltered] = useState()
    const [onClear, setOnClear] = useState(false)

    const [isFilter, setIsFilter] = useState(false)
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })
    let expertsMax = [0, 1]
    projects && projects.data.map(item => {
        expertsMax.push(item.experts.length)
    })

    const onSubmit = (filteredData) => {

        let arrFilterItems = Object.keys(filteredData).map((item, index) => {
            if (Object.values(filteredData)[index] !== '' && Object.values(filteredData)[index] !== undefined && Object.values(filteredData)[index] !== "Invalid date") {
                return `filter[${item}]=${Object.values(filteredData)[index]}`
            }
        }).filter(item => item !== undefined)
        let newArr = arrFilterItems.map((item, index) => index == arrFilterItems.length - 1 ? `${item}` : `${item}&`)
        setFilteredData(newArr.toString().replace(/[,]/gi, ''))
    }

    const ExpertData = projects && projects.data.map(item => {
        const data = {
            'id': item.id,
            'Наименование НПА': item.name[i18n.language],
            'Орган разработчик': item.authority_developer_name[i18n.language],
            'Вид НПА': item.document_type_name[i18n.language],
            'Группа НПА': item.document_type_group[i18n.language],
        };
        [...Array(Math.max.apply(null, expertsMax))].map((_, index) => {
            return (
                data['Эксперт ' + (index + 1)] = item.experts[index] ? item.experts[index].full_name : 'нет данных',
                data['Кол-во замечаний эксперт ' + (index + 1)] = item.experts[index] ? item.experts[index].remark_count : 0,
                data['БП эксперт ' + (index + 1)] = item.experts[index] ? item.baseline_cost / item.expert_count : 0,
                data['Экспертная часть ' + (index + 1)] = item.experts[index] ? item.baseline_cost / item.expert_count * item.experts[index].remark_count : 'нет данных'
            )
        })
        data['Базовый показатель общий'] = item.baseline_cost
        data['Экспертная часть общая'] = item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0)
        data['БП координатор'] = item.coordinator_cost
        data['Стоимость НПА без НДС'] = item.experts.length > 0 ? item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0) + item.coordinator_cost : 0
        data['Стоимость НПА с НДС'] = item.experts.length > 0 ? Math.round((item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0) + item.coordinator_cost) * 1.12) : 0
        return data
    })

    const ExpertData2 = npaData && npaData.data.map(item => {

        const data = {
            'Период': 'Сентябрь',
            'Группа НПА': item.group,
            'Кол-во проектов': item.project_count ? item.project_count : "нет данных",
            'Кол-во замечаний': item.remark_count ? item.remark_count : "нет данных",
            'Экспертная часть': item.price ? item.price : "нет данных",
            'Координатор': item.coordinator_price ? item.coordinator_price : "нет данных",
            'Стоимость с НДС': item.sum_with_vat ? item.sum_with_vat : "нет данных",
            'Стоимость без НДС': item.sum_without_vat ? item.sum_without_vat : "нет данных",
        };
        return data
    })

    return (
        <div className={'projects_page'}>
            <div className="header_wrapper_name">
                <h1>{t("project")}</h1>
                {userLocation == '/clerk' ? <Link to={'/clerk/addProject'}><a className={'add_project'}><span>{t("add_project")}</span> {IconsContent.AddProject()}</a></Link> : null}
            </div>
            <main >
                <div className="main_header">
                    <button className={'btn_filter'} onClick={() => { setIsFilter(true) }}>
                        <span className="name">Фильтр</span>
                        {IconsContent.Filter()}
                    </button>
                    <form className={"btn_search"}>
                        {IconsContent.Search()}
                        <input type="email" placeholder={t("search")}  {...register("name", { onChange: () => handleSubmit(onSubmit)() })} />
                    </form>
                    <div className="btn_export">
                        <ExportExcel excelData={ExpertData} fileName={'ExcelNewExport'} excelData2={ExpertData2} />
                    </div>
                </div>

                <div className="table_wrapper" style={{ overflowX: "scroll" }}>
                    <table className={'table_styling'}>
                        <thead>
                            <tr className={'table_heading'}>
                                <TableFirst />
                                <td style={{ minWidth: "500px" }}>{t("project_name")}</td>
                                <td style={{ minWidth: "200px" }}>{t("authority_developer")}</td>
                                <td style={{ minWidth: "230px" }}>{t("document_type")}</td>
                                <td style={{ minWidth: "100px" }}>{t("groupNPA")}</td>
                                {[...Array(Math.max.apply(null, expertsMax))].map((item, index) => {
                                    return (
                                        <>
                                            <td style={{ minWidth: "180px", textAlign: "center", borderLeft: "1px solid white" }}>
                                                {t("expert")} {index + 1}
                                            </td>
                                            <td style={{ minWidth: "100px", textAlign: "center" }}>
                                                {t("remark_count")}
                                            </td>
                                            <td style={{ minWidth: "180px", textAlign: "center" }}>
                                                {t("base_index_expert")} {index + 1}
                                            </td>
                                            <td style={{ minWidth: "100px", textAlign: "center" }}>
                                                {t("remark_cost")}
                                            </td>
                                            <td style={{ minWidth: "100px", textAlign: "center", borderRight: "1px solid white" }}>
                                                {t("expert_part")} {index + 1}
                                            </td>
                                        </>)
                                })}
                                <td style={{ minWidth: "180px" }}>{t("base_index")}</td>
                                <td style={{ minWidth: "200px" }}>{t("expert_part_overall")}</td>
                                <td style={{ minWidth: "100px" }}>{t("coordinator")}</td>
                                <td style={{ minWidth: "180px" }}>{t("priceNPAwithoutVAT")}</td>
                                <td style={{ minWidth: "180px" }}>{t("priceNPAwithVAT")}</td>
                                <td className={'last'}></td>

                            </tr>
                        </thead>

                        {projects && projects.data.map((item, index) => {
                            return (
                                <tbody>
                                    <tr className={'table_body'} key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.name[i18n.language]} </td>
                                        <td>{item.authority_developer_name[i18n.language]}</td>
                                        <td>{item.document_type_name[i18n.language]}</td>
                                        <td>{item.document_type_group[i18n.language]}</td>
                                        {[...Array(Math.max.apply(null, expertsMax))].map((val, index) => {
                                            return (
                                                <>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.experts[index] ? item.experts[index].full_name : "нет данных"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.experts[index] ? item.experts[index].remark_count : "нет данных"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.experts[index] ? Math.round(item.baseline_cost / item.expert_count) : "нет данных"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.experts[index] ? item.risk_cost : "нет данных"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {item.experts[index] ? (item.baseline_cost / item.expert_count + item.experts[index].remark_count * item.risk_cost) : "нет данных"}
                                                    </td>
                                                </>)
                                        })}
                                        <td style={{ textAlign: "center" }}>{item.baseline_cost}</td>
                                        <td style={{ textAlign: "center" }}>{item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0)}</td>
                                        <td style={{ textAlign: "center" }}>{item.coordinator_cost}</td>
                                        <td style={{ textAlign: "center" }}>{item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0) + item.coordinator_cost}</td>
                                        <td style={{ textAlign: "center" }}>{Math.round((item.experts.map(val => item.baseline_cost / item.expert_count + val.remark_count * item.risk_cost).reduce((acc, num) => acc + num, 0) + item.coordinator_cost) * 1.12)}</td>
                                        <td></td>

                                    </tr>

                                </tbody>
                            )
                        })}
                    </table>

                </div>
                <div className="table_wrapper_footer" style={{ marginTop: "10px" }}>
                    <div className="footer_number">
                        {projects && <p>Найдено {projects.meta.total}</p>}
                    </div>
                    <div className="footer_pagination">
                        {projects && projects.meta?.total > projects.meta?.per_page ? [...Array(Math.ceil(projects.meta.total / projects.meta?.per_page))].map((item, index) =>
                            <button className={filteredPage == index + 1 ? 'btn_pagination active' : 'btn_pagination'} onClick={() => setFilteredPage(index + 1)}> {index + 1}</button>) : null}
                        {/* <button className={'btn_pagination '}>1</button>
                                <button className={'btn_pagination'}>2</button> */}
                    </div>
                </div>



            </main>
            <div className={`modal_filter ${isFilter ? 'active' : ''}`}>
                <div className={`modal_content`}>
                    <div className="modal_header">
                        <div className="header_word">
                            <h5>Фильтр</h5>
                            {IconsContent.Filter('#4ABD9B', `2`)}
                        </div>
                        <button onClick={() => { setIsFilter(false) }}>{IconsContent.Cancel()}</button>
                    </div>
                    <div className="modal_form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="input_wrapper">
                                <label htmlFor="">ID</label>
                                <input type="number" {...register(`id`)} />
                            </div>

                            <div className="input_wrapper">
                                <label htmlFor="">Тип документа</label>
                                <InputSelect control={control} name="document_type_id" noRequired onClear={onClear} placeholder="Выберите" options={documentTypes} />
                            </div>

                            <div className="input_wrapper ">
                                <label htmlFor="">Эксперт</label>
                                <InputSelect control={control} name="expert_id" noRequired onClear={onClear} placeholder="Выберите" options={expertsList} />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Орган разработчик</label>
                                <InputSelect control={control} name="authority_developer_id" noRequired onClear={onClear} placeholder="Выберите" options={authorityDevelopers} />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Период (дата подписи экспертом)</label>
                                <DatePickerInputRange nameFrom='filtered_expert_date_from' onClear={onClear} control={control} nameTill='filtered_expert_date_till' />
                            </div>
                            <div className="input_wrapper ">
                                <label htmlFor="">Период (дата опубликования)</label>
                                <DatePickerInputRange nameFrom='filtered_publicate_date_from' onClear={onClear} control={control} nameTill='filtered_publicate_date_till' />
                            </div>
                            <div className="filter__ender">
                                <button type="submit" className={'submit'}>Применить</button>
                                <button type="button" onClick={(e) => { setOnClear(!onClear); reset() }} className={'submit reset '}>Сбросить</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal_cover" onClick={() => { setIsFilter(false) }} />
            </div>


        </div>
    )
}
{/* <button className={'cancel'}>Сбросить</button> */ }
// /api/v1/projects?filter[id]=1000000&filter[status][id]=1&filter[name]=*some text*&filter[branch_of_legislation_id]=111&filter[coordinator_id]=11&filter[expert_id]=111&filter[document_type_id]=111&filter[interpreter_id]=1111&filter[authority_developer_id]=111&filter[deadline]=2022-10-10&filter[published]=1