import NavigationContent from "../../NavigationContent/NavigationContent";
import { Link } from "react-router-dom";
import IconsContent from "../../../Assets/img/IconsContent";
import { useState, useEffect, useRef } from "react";
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { UserService } from "../../../../Main/Services/UserService";
import AnimateHeight from 'react-animate-height';
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select';
import Wysiwyg from "../../Wysiwyg/Wysiwyg";
import Preloader from "../../Preloader/Preloader";
import AiForm from "./AiForm";
import { useTranslation } from "react-i18next";


const Factors = ({ index, factorsAmount, setFactorsAmount, control, register, conclusionLang, detailed_analysis_of_risk_factors, riskFactors, corruptionFactors }) => {
    const { t, i18n } = useTranslation("common");
    console.log(detailed_analysis_of_risk_factors)
    const [heightFactors, setHeightFactors] = useState(0)

    return (
        <div key={index} >
            <input type="text" style={{ display: "none" }} defaultValue={detailed_analysis_of_risk_factors[index]?.id} {...register(`detailed_analysis_of_risk_factors[${index}].id`)} />
            <button type="button" aria-expanded={heightFactors !== 0} aria-controls="example-panel-3" onClick={() => setHeightFactors(heightFactors === 0 ? 'auto' : 0)}>
                {t("detailed_analysis")} {index + 1} {index == factorsAmount - 1 ?
                    <span onClick={(e) => { setFactorsAmount(factorsAmount + 1); e.stopPropagation() }} className="factor__add">Добавить</span> : null}
                {index == factorsAmount - 1 && factorsAmount > 1 ? <span className="factor__delete" onClick={(e) => { setFactorsAmount(factorsAmount - 1); UserService.detailedFactors(detailed_analysis_of_risk_factors[index]?.id); e.stopPropagation() }}>Удалить</span> : null}
                {heightFactors === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
            </button>
            <AnimateHeight id="example-panel-3" duration={500} height={heightFactors} >
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("article_kk")}</label>
                    {/* <Wysiwyg control={control} name={`article_kk[${index}]`}  defaultValu={conclusion.locales.article.kk[index]}/> */}
                    <textarea type="text" defaultValue={detailed_analysis_of_risk_factors[index]?.locales?.article.kk}  {...register(`detailed_analysis_of_risk_factors[${index}].article_kk`)} />
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("article_ru")}</label>
                    {/* <Wysiwyg control={control} name={`article_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.article_ru} /> */}
                    <textarea type="text" defaultValue={detailed_analysis_of_risk_factors[index]?.locales?.article.ru} {...register(`detailed_analysis_of_risk_factors[${index}].article_ru`)} />
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("part_kk")}</label>
                    {/* <Wysiwyg control={control} name={`part_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.part.kk} /> */}
                    <textarea type="text" defaultValue={detailed_analysis_of_risk_factors[index]?.locales?.part.kk} {...register(`detailed_analysis_of_risk_factors[${index}].part_kk`)} />
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("part_ru")}</label>
                    {/* <Wysiwyg control={control} name={`part_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.part.ru} /> */}
                    <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.part.ru} {...register(`detailed_analysis_of_risk_factors[${index}].part_ru`)} />
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("point_kk")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].point_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.point.kk} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.point.kk} className={errors.point_kk ? "form-error-border-color" : ""} {...register("point_kk",)} /> */}
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("point_ru")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].point_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.point.ru} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.poont_ru} className={errors.point_ru ? "form-error-border-color" : ""} {...register("point_ru",)} /> */}
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("sub_paragraph_kk")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].sub_paragraph_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.sub_paragraph.kk} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.sub_paragraph.kk} className={errors.sub_paragraph_kk ? "form-error-border-color" : ""} {...register("sub_paragraph_kk",)} /> */}
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("sub_paragraph_ru")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].sub_paragraph_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.sub_paragraph.ru} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.sub_paragraph.ru} className={errors.sub_paragraph_ru ? "form-error-border-color" : ""} {...register("sub_paragraph_ru",)} /> */}
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("the_text_of_the_problematic_norm_kk")} </label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].the_text_of_the_problematic_norm_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.the_text_of_the_problematic_norm.kk} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.the_text_of_the_problematic_norm.kk} className={errors.the_text_of_the_problematic_norm_kk ? "form-error-border-color" : ""} {...register("the_text_of_the_problematic_norm_kk",)} /> */}
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("the_text_of_the_problematic_norm_ru")} </label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].the_text_of_the_problematic_norm_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.the_text_of_the_problematic_norm.ru} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.the_text_of_the_problematic_norm.ru} className={errors.the_text_of_the_problematic_norm_ru ? "form-error-border-color" : ""} {...register("the_text_of_the_problematic_norm_ru",)} /> */}
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("remark_kk")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].remark_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.remark.kk} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.remark.kk} className={errors.remark_kk ? "form-error-border-color" : ""} {...register("remark_kk",)} /> */}
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("remark_ru")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].remark_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.remark.ru} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.remark.ru} className={errors.remark_ru ? "form-error-border-color" : ""} {...register("remark_ru",)} /> */}
                </div> : null}
                {conclusionLang == 1 ? <div className="input_wrapper">
                    <label htmlFor="">{t("recommendations_kk")}</label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].recommendations_kk`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.recommendations.kk} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.recommendations.kk} className={errors.recommendations_kk ? "form-error-border-color" : ""} {...register("recommendations_kk",)} /> */}
                </div> : null}
                {conclusionLang == 2 ? <div className="input_wrapper">
                    <label htmlFor="">{t("recommendations_ru")} </label>
                    <Wysiwyg control={control} name={`detailed_analysis_of_risk_factors[${index}].recommendations_ru`} defaultValu={detailed_analysis_of_risk_factors[index]?.locales?.recommendations.ru} />
                    {/* <textarea type="text" defaultValu={detailed_analysis_of_risk_factors[index].recommendations.ru} className={errors.recommendations_ru ? "form-error-border-color" : ""} {...register("recommendations_ru",)} /> */}
                </div> : null}
                <div className="input_wrapper">
                    <label htmlFor="">{t("risk_factor")}</label>
                    <Controller
                        name={`detailed_analysis_of_risk_factors[${index}].risk_factor_id`}
                        control={control}
                        render={({ field: { onChange, value } }) =>
                            <Select
                                onChange={(e) => { onChange(e?.value); }}
                                value={riskFactors.filter(item => value ? value == item.value : detailed_analysis_of_risk_factors[index]?.locales.risk_factor_id == item.value)}
                                classNamePrefix="custom-select"
                                placeholder={"Выберите"}
                                options={riskFactors}
                            />
                        } />
                </div>
                <div className="input_wrapper">
                    <label htmlFor="">{t("corruption_risks")}</label>
                    <Controller
                        name={`detailed_analysis_of_risk_factors[${index}].corruption_risks`}
                        control={control}
                        render={({ field: { onChange, ref, value } }) =>
                            <Select
                                isMulti
                                value={corruptionFactors.filter(item => value ? value.includes(item.value) : detailed_analysis_of_risk_factors[index]?.locales.corruption_risks.find(val => val[0].ru == item.label))}
                                onChange={(val) => { onChange(val.map((c) => c.value)) }}
                                options={corruptionFactors}
                                placeholder="Выберите"
                                classNamePrefix="custom-select"

                            />
                        } />
                </div>
            </AnimateHeight>

        </div>
    )
}

export default function ConclusionsEditContainer({ conclusion, riskFactors, corruptionFactors }) {
    const { t, i18n } = useTranslation("common");
    const navigate = useNavigate()
    const conclusionID = useLocation().pathname.replace(/[a-z\/]/g, '')
    const [isShowModalConclusionEdited, setIsShowModalConclusionEdited] = useState(false)
    const conclusionFormLang = [1, 2]
    const [conclusionLang, setConclusionLang] = useState(1)
    const [isShowModalConclusionFinal, setIsShowModalConclusionFinal] = useState(false)
    const [finalEditConclusion, setFinalsEditConclusion] = useState(0)
    const [fromDraft, setFromDraft] = useState(false)
    const [factorsAmount, setFactorsAmount] = useState(conclusion.detailed_analysis_of_risk_factors.length)
    const [isShowModalConclusionDraft, setIsShowModalConclusionDraft] = useState(false)
    const [isShowModalAi, setIsShowModalAi] = useState(false)
    const { handleSubmit, register, control, reset, formState: { errors } } = useForm({ mode: 'onBlur' })
    const [newPlayAnimation, setNewPlayAnimation] = useState(false)
    const [isShowModalError, setIsShowModalError] = useState(false);

    const onSubmit = (conclusionData) => {
        setNewPlayAnimation(true);
        conclusionData = { ...conclusionData, detailed_analysis_of_risk_factors: [...Array(factorsAmount)].map((item, index) => conclusionData.detailed_analysis_of_risk_factors[index]) }

        if (fromDraft) {
            if (conclusion.status.id == 13 || (conclusion.status.id == 10 && ((conclusion.locales.corrector_document_src.kk !== null || conclusion.locales.corrector_document_src.ru !== null) && (conclusion.locales.translater_document_src.kk !== null || conclusion.locales.translater_document_src.ru !== null)))) {
                setFinalsEditConclusion(conclusionData)
                setNewPlayAnimation(false);
                setIsShowModalConclusionFinal(true);
            } else {
                UserService.editConclusion(conclusionID, conclusionData)
                    .then(response => {
                        {
                            conclusion.status.id == 12 ?
                                UserService.putConclusion(conclusion.id, { status_id: 7 })
                                    .then(() => { setNewPlayAnimation(false); setIsShowModalConclusionEdited(true) })
                                    .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
                                :
                                conclusion.status.id == 13 ||
                                    (conclusion.status.id == 10 && ((conclusion.locales.corrector_document_src.kk !== null || conclusion.locales.corrector_document_src.ru !== null) && (conclusion.locales.translater_document_src.kk !== null || conclusion.locales.translater_document_src.ru !== null))) ?
                                    UserService.putConclusion(conclusion.id, { status_id: 8 })
                                        .then(() => { setNewPlayAnimation(false); setIsShowModalConclusionEdited(true) })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
                                    :
                                    UserService.putConclusion(conclusion.id, { status_id: 4 })
                                        .then(() => { setNewPlayAnimation(false); setIsShowModalConclusionEdited(true) })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
                        }
                    })
                    .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
            }
        } else {
            UserService.editConclusion(conclusionID, conclusionData)
                .then(response => { setNewPlayAnimation(false); setIsShowModalConclusionDraft(true) })
                .catch(() => { setNewPlayAnimation(false); setIsShowModalError(true) })
        }
        // ;
    }

    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [height3, setHeight3] = useState(0);
    const [height4, setHeight4] = useState(0);

    return (
        <div className={'addProject_page transmittal-letters__form conclusion__form '}>
            {newPlayAnimation && <Preloader playAnimation={false} />}
            <NavigationContent direct={[
                { goTo: `/expert`, name: `Главная` },
                { goTo: `/expert/conclusions`, name: `Заключения` },
                { goTo: `/expert/conclusions/edit/${3}`, name: `Редактирование заключения` }
            ]} name={t("expertise_form")} ai={
                <div className="ai">
                    <p className="ai__header">{t("ai_conclusion")}</p>
                    <a className="ai__download" href={conclusion.project.law_analysis.src}>{t("ai_download")}</a>
                    <button className="ai__view" onClick={() => setIsShowModalAi(true)}>{t("ai_preview")}</button>
                </div>} />

            <main>

                <form className={'form_catcher-expert'} onSubmit={handleSubmit(onSubmit)}>
                    <div className="users__role users__role-conclusion">
                        {conclusionFormLang.map((item, index) => <span key={index} className={conclusionLang == item ? 'users__role-active' : ''} onClick={() => setConclusionLang(item)}>{item == 1 ? t("conclusion_form_kk") : t("conclusion_form_ru")}</span>)}
                    </div>

                    <button type="button" aria-expanded={height !== 0} aria-controls="example-panel" onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
                        <span>{t("conclusion_form_first_part")}</span>
                        {height === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                    </button>
                    <AnimateHeight id="example-panel" duration={500} height={height} >
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("availability_of_competence_of_the_developers_authority_kk")}</label>
                            <Wysiwyg control={control} name={"availability_of_competence_of_the_developers_authority_kk"} defaultValu={conclusion.locales.availability_of_competence_of_the_developers_authority.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.availability_of_competence_of_the_developers_authority.kk} className={errors.availability_of_competence_of_the_developers_authority_kk ? "form-error-border-color" : ""} {...register("availability_of_competence_of_the_developers_authority_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("availability_of_competence_of_the_developers_authority_ru")}</label>
                            <Wysiwyg control={control} name={"availability_of_competence_of_the_developers_authority_ru"} defaultValu={conclusion.locales.availability_of_competence_of_the_developers_authority.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.availability_of_competence_of_the_developers_authority.ru} className={errors.availability_of_competence_of_the_developers_authority_ru ? "form-error-border-color" : ""} {...register("availability_of_competence_of_the_developers_authority_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("compliance_with_the_requirements_kk")}</label>
                            <Wysiwyg control={control} name={"compliance_with_the_requirements_kk"} defaultValu={conclusion.locales.compliance_with_the_requirements.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.compliance_with_the_requirements.kk} className={errors.compliance_with_the_requirements_kk ? "form-error-border-color" : ""} {...register("compliance_with_the_requirements_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("compliance_with_the_requirements_ru")}</label>
                            <Wysiwyg control={control} name={"compliance_with_the_requirements_ru"} defaultValu={conclusion.locales.compliance_with_the_requirements.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.compliance_with_the_requirements.ru} className={errors.compliance_with_the_requirements_ru ? "form-error-border-color" : ""} {...register("compliance_with_the_requirements_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("the_stated_and_real_purpose_of_the_project_kk")}</label>
                            <Wysiwyg control={control} name={"the_stated_and_real_purpose_of_the_project_kk"} defaultValu={conclusion.locales.the_stated_and_real_purpose_of_the_project.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.the_stated_and_real_purpose_of_the_project.kk} className={errors.the_stated_and_real_purpose_of_the_project_kk ? "form-error-border-color" : ""} {...register("the_stated_and_real_purpose_of_the_project_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("the_stated_and_real_purpose_of_the_project_ru")}</label>
                            <Wysiwyg control={control} name={"the_stated_and_real_purpose_of_the_project_ru"} defaultValu={conclusion.locales.the_stated_and_real_purpose_of_the_project.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.the_stated_and_real_purpose_of_the_project.ru} className={errors.the_stated_and_real_purpose_of_the_project_ru ? "form-error-border-color" : ""} {...register("the_stated_and_real_purpose_of_the_project_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("public_interest_and_private_interests_kk")}</label>
                            <Wysiwyg control={control} name={"public_interest_and_private_interests_kk"} defaultValu={conclusion.locales.public_interest_and_private_interests.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.public_interest_and_private_interests.kk} className={errors.public_interest_and_private_interests_kk ? "form-error-border-color" : ""} {...register("public_interest_and_private_interests_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("public_interest_and_private_interests_ru")}</label>
                            <Wysiwyg control={control} name={"public_interest_and_private_interests_ru"} defaultValu={conclusion.locales.public_interest_and_private_interests.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.public_interest_and_private_interests.ru} className={errors.public_interest_and_private_interests_ru ? "form-error-border-color" : ""} {...register("public_interest_and_private_interests_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("sufficiency_of_argumentation_kk")}</label>
                            <Wysiwyg control={control} name={"sufficiency_of_argumentation_kk"} defaultValu={conclusion.locales.sufficiency_of_argumentation.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.sufficiency_of_argumentation.kk} className={errors.sufficiency_of_argumentation_kk ? "form-error-border-color" : ""} {...register("sufficiency_of_argumentation_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("sufficiency_of_argumentation_ru")}</label>
                            <Wysiwyg control={control} name={"sufficiency_of_argumentation_ru"} defaultValu={conclusion.locales.sufficiency_of_argumentation.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.sufficiency_of_argumentation.ru} className={errors.sufficiency_of_argumentation_ru ? "form-error-border-color" : ""} {...register("sufficiency_of_argumentation_ru",)} /> */}
                            {/* {errors.sufficiency_of_argumentation_ru && <p className="form--required">{errors.sufficiency_of_argumentation_ru.message}</p>} */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("financial_and_economic_justification_kk")}</label>
                            <Wysiwyg control={control} name={"financial_and_economic_justification_kk"} defaultValu={conclusion.locales.financial_and_economic_justification.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.financial_and_economic_justification.kk} className={errors.financial_and_economic_justification_kk ? "form-error-border-color" : ""} {...register("financial_and_economic_justification_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("financial_and_economic_justification_ru")}</label>
                            <Wysiwyg control={control} name={"financial_and_economic_justification_ru"} defaultValu={conclusion.locales.financial_and_economic_justification.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.financial_and_economic_justification.ru} className={errors.financial_and_economic_justification_ru ? "form-error-border-color" : ""} {...register("financial_and_economic_justification_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("conducting_regulatory_impact_analysis_kk")}</label>
                            <Wysiwyg control={control} name={"conducting_regulatory_impact_analysis_kk"} defaultValu={conclusion.locales.conducting_regulatory_impact_analysis.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.conducting_regulatory_impact_analysis.kk} className={errors.conducting_regulatory_impact_analysis_kk ? "form-error-border-color" : ""} {...register("conducting_regulatory_impact_analysis_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("conducting_regulatory_impact_analysis_ru")}</label>
                            <Wysiwyg control={control} name={"conducting_regulatory_impact_analysis_ru"} defaultValu={conclusion.locales.conducting_regulatory_impact_analysis.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.conducting_regulatory_impact_analysis.ru} className={errors.conducting_regulatory_impact_analysis_ru ? "form-error-border-color" : ""} {...register("conducting_regulatory_impact_analysis_ru",)} /> */}
                        </div> : null}
                    </AnimateHeight>

                    <button type="button" aria-expanded={height2 !== 0} aria-controls="example-panel-2" onClick={() => setHeight2(height2 === 0 ? 'auto' : 0)}>
                        <span>{t("conclusion_form_scond_part")}</span>
                        {height2 === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                    </button>
                    <AnimateHeight id="example-panel-2" duration={500} height={height2} >
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("project_language_kk")}</label>
                            <Wysiwyg control={control} name={"the_language_of_the_npa_project_kk"} defaultValu={conclusion.locales.the_language_of_the_npa_project.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.the_language_of_the_npa_project.kk} className={errors.the_language_of_the_npa_project_kk ? "form-error-border-color" : ""} {...register("the_language_of_the_npa_project_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("project_language_ru")}</label>
                            <Wysiwyg control={control} name={"the_language_of_the_npa_project_ru"} defaultValu={conclusion.locales.the_language_of_the_npa_project.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.the_language_of_the_npa_project.ru} className={errors.the_language_of_the_npa_project_ru ? "form-error-border-color" : ""} {...register("the_language_of_the_npa_project_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("legislative_consistency_of_the_project_kk")}</label>
                            <Wysiwyg control={control} name={"legislative_consistency_of_the_project_kk"} defaultValu={conclusion.locales.the_language_of_the_npa_project.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.legislative_consistency_of_the_project.kk} className={errors.legislative_consistency_of_the_project_kk ? "form-error-border-color" : ""} {...register("legislative_consistency_of_the_project_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("legislative_consistency_of_the_project_ru")}</label>
                            <Wysiwyg control={control} name={"legislative_consistency_of_the_project_ru"} defaultValu={conclusion.locales.the_language_of_the_npa_project.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.legislative_consistency_of_the_project.ru} className={errors.legislative_consistency_of_the_project_ru ? "form-error-border-color" : ""} {...register("legislative_consistency_of_the_project_ru",)} /> */}
                        </div> : null}
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("competence_kk")}</label>
                            <Wysiwyg control={control} name={"competence_kk"} defaultValu={conclusion.locales.competence.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.competence.kk} className={errors.competence_kk ? "form-error-border-color" : ""} {...register("competence_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("competence_ru")}</label>
                            <Wysiwyg control={control} name={"competence_ru"} defaultValu={conclusion.locales.competence.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.competence.ru} className={errors.competence_ru ? "form-error-border-color" : ""} {...register("competence_ru",)} /> */}
                        </div> : null}
                    </AnimateHeight>

                    {[...Array(factorsAmount)].map((item, index) =>
                        <Factors index={index} setFactorsAmount={setFactorsAmount} factorsAmount={factorsAmount} corruptionFactors={corruptionFactors} control={control} register={register}
                            conclusionLang={conclusionLang} detailed_analysis_of_risk_factors={conclusion.detailed_analysis_of_risk_factors} riskFactors={riskFactors} />)}

                    <button type="button" aria-expanded={height4 !== 0} aria-controls="example-panel-4" onClick={() => setHeight4(height4 === 0 ? 'auto' : 0)}>
                        <span>Вывод заключения</span>
                        {height4 === 0 ? IconsContent.ArrDown() : IconsContent.ArrUp()}
                    </button>
                    <AnimateHeight id="example-panel-4" duration={500} height={height4} >
                        {conclusionLang == 1 ? <div className="input_wrapper">
                            <label htmlFor="">{t("output_text_kk")}</label>
                            <Wysiwyg control={control} name={"output_text_kk"} defaultValu={conclusion.locales.output_text.kk} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.output_text.kk} className={errors.output_text_kk ? "form-error-border-color" : ""} {...register("output_text_kk",)} /> */}
                        </div> : null}
                        {conclusionLang == 2 ? <div className="input_wrapper">
                            <label htmlFor="">{t("output_text_ru")}</label>
                            <Wysiwyg control={control} name={"output_text_ru"} defaultValu={conclusion.locales.output_text.ru} />
                            {/* <textarea type="text" defaultValu={conclusion.locales.output_text.ru} className={errors.output_text_ru ? "form-error-border-color" : ""} {...register("output_text_ru",)} /> */}
                        </div> : null}

                    </AnimateHeight>
                    {conclusion.status.id == 12 ?
                        <div className="save">
                            <button className="save_draft">{t("save_to_draft")}</button>
                            <button className="save_push" onClick={() => setFromDraft(true)} >{t("send_to_translator")}</button>
                        </div> :
                        conclusion.status.id == 13 || (conclusion.status.id == 10 && ((conclusion.locales.corrector_document_src.kk !== null || conclusion.locales.corrector_document_src.ru !== null) && (conclusion.locales.translater_document_src.kk !== null || conclusion.locales.translater_document_src.ru !== null)))
                            ?
                            <div className="save">
                                <button className="save_draft" >{t("save_to_draft")}</button>
                                <button className="save_push" style={{ width: 'auto' }} onClick={() => setFromDraft(true)} >{t("send_final_conclusion")}</button>
                            </div> :
                            <div className="save">
                                <button className="save_draft">{t("save_to_draft")}</button>
                                <button className="save_push" onClick={() => setFromDraft(true)}>{t("send_to_coordinator")}</button>
                            </div>}
                </form>

                <div className={isShowModalConclusionEdited ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            {/* <span onClick={() => setIsShowModalConclusionEdited(false)}>{IconsContent.Close()}</span> */}
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            {conclusion.status.id == 12 ?
                                <p>Заключение отправлено переводчику</p> :
                                <p>Заключение отправлено координатору</p>}
                            <button onClick={() => navigate('/expert/conclusions/')}>Закрыть</button>
                        </div>
                    </div>
                </div>
                <div className={isShowModalConclusionDraft ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            {/* <span onClick={() => setIsShowModalConclusionDraft(false)}>{IconsContent.Close()}</span> */}
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>

                            <p>Заключение сохранено в черновиках</p>

                            <button onClick={() => window.location.reload()}>Закрыть</button>
                        </div>
                    </div>
                </div>

                <div className={isShowModalConclusionFinal ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalConclusionFinal(false)}>{IconsContent.Close()}</span>
                        </p>
                        <div>
                            <p>{IconsContent.DocCreated()}</p>
                            <p style={{ padding: "10px 30px", textAlign: "center" }}>Вы отправляете предварительную версию экспертизы Уполномоченному координатору</p>
                            <p>
                                <button className='header__content-correct' onClick={() => {
                                    setNewPlayAnimation(true);
                                    UserService.editConclusion(conclusionID, finalEditConclusion)
                                        .then(response => {
                                            UserService.putConclusion(conclusion.id, { status_id: 8 })
                                                .then(response => {
                                                    setNewPlayAnimation(false);
                                                    setIsShowModalConclusionFinal(false);
                                                    setIsShowModalConclusionEdited(true)
                                                })
                                        })
                                        .catch(() => { setNewPlayAnimation(false); setIsShowModalConclusionFinal(false); setIsShowModalError(true) })
                                }}>Принять</button>
                                <button style={{ marginLeft: "20px" }} onClick={() => setIsShowModalConclusionFinal(false)}>Закрыть</button>
                            </p>

                        </div>
                    </div>
                </div>
                <div className={isShowModalError ? "transmittal-letters__modal transmittal-letters__modal-active" : "transmittal-letters__modal"}>
                    <div className="isShowModalDocCreates" onClick={(e) => e.stopPropagation()}>
                        <p>
                            <span onClick={() => setIsShowModalError(false)}>
                                {IconsContent.Close()}
                            </span>
                        </p>
                        <div>
                            <p>{IconsContent.Error()}</p>
                            <p>Произошла ошибка</p>
                            <button onClick={() => {
                                setIsShowModalError(false);
                                window.location.reload();
                            }}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
                <AiForm isShowModalAi={isShowModalAi} setIsShowModalAi={setIsShowModalAi} comments={conclusion.project?.law_analysis?.comments} />
            </main>

        </div >
    )
}

